<style lang="scss" scoped>
.page-merchant-record {
    /deep/ .avatar-uploader {
        .el-upload {
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
        }

        .el-upload:hover {
            border-color: #409eff;
        }

        .avatar-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            width: 450px;
            height: 250px;
            line-height: 250px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .avatar {
            width: 450px;
            height: 250px;
            display: block;
        }
    }
    .pages {
        display: flex;
        justify-content: space-around;
    }
    .imgz {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 450px;
        margin-bottom: 30px;
    }
    .wen {
        font-weight: bold;
    }
}
</style>

<template>
    <div class="page-merchant-record">
        <div class="pages">
            <div class="imgz">
                <el-upload class="avatar-uploader" :action="uploadUrl" :show-file-list="false" 
                    :on-success="(res, file, fileList) => { handleAvatarSuccess(res, file, fileList, 'contractFImage') }" :before-upload="beforeUpload" :disabled="!!recordImg.contractFImage">
                    <a :href="recordImg.contractFImage" target="_blank" v-if="recordImg.contractFImage">
                        <img :src="recordImg.contractFImage" class="avatar" />
                    </a>
                    <i v-else class="avatar-uploader-icon">
                        <i class="el-icon-plus"></i>
                    </i>
                </el-upload>
                <div class="wen">合同正面照</div>
            </div>
            <div class="imgz">
                <el-upload class="avatar-uploader" :action="uploadUrl" :show-file-list="false" 
                    :on-success="(res, file, fileList) => { handleAvatarSuccess(res, file, fileList, 'contractBImage') }" :before-upload="beforeUpload" :disabled="!!recordImg.contractBImage">
                    <a :href="recordImg.contractBImage" target="_blank" v-if="recordImg.contractBImage">
                        <img :src="recordImg.contractBImage" class="avatar" />
                    </a>
                    <i v-else class=" avatar-uploader-icon">
                        <i class="el-icon-plus"></i>
                    </i>
                </el-upload>
                <div class="wen">合同反面照</div>
            </div>
        </div>
        <div class="pages">
            <div class="imgz">
                <el-upload class="avatar-uploader" :action="uploadUrl" :show-file-list="false" 
                    :on-success="(res, file, fileList) => { handleAvatarSuccess(res, file, fileList, 'idCardFImage') }" :before-upload="beforeUpload" :disabled="!!recordImg.idCardFImage">
                    <a v-if="recordImg.idCardFImage" :href="recordImg.idCardFImage" target="_blank">
                        <img :src="recordImg.idCardFImage" class="avatar" />
                    </a>
                    <i v-else class=" avatar-uploader-icon">
                        <i class="el-icon-plus"></i>
                    </i>
                </el-upload>
                <div class="wen">身份证正面照</div>
            </div>
            <div class="imgz">
                <el-upload class="avatar-uploader" :action="uploadUrl" :show-file-list="false" 
                    :on-success="(res, file, fileList) => { handleAvatarSuccess(res, file, fileList, 'idCardBImage') }" :before-upload="beforeUpload" :disabled="!!recordImg.idCardBImage">
                    <a v-if="recordImg.idCardBImage" :href="recordImg.idCardBImage" target="_blank">
                        <img :src="recordImg.idCardBImage" class="avatar" />
                    </a>
                    <i v-else class=" avatar-uploader-icon">
                        <i class="el-icon-plus"></i>
                    </i>
                </el-upload>
                <div class="wen">身份证反面照</div>
            </div>
        </div>
        <div style="display: flex; justify-content: space-around; width: 100%">
            <div class="imgz">
                <el-upload class="avatar-uploader" :action="uploadUrl" :show-file-list="false" 
                    :on-success="(res, file, fileList) => { handleAvatarSuccess(res, file, fileList, 'businessLicenseImage') }" :before-upload="beforeUpload" :disabled="!!recordImg.businessLicenseImage">
                    <a v-if="recordImg.businessLicenseImage" :href="recordImg.businessLicenseImage" target="_blank">
                        <img :src="recordImg.businessLicenseImage" class="avatar" />
                    </a>
                    <i v-else class="avatar-uploader-icon" style="width: 600px">
                        <i class="el-icon-plus"></i>
                    </i>
                </el-upload>
                <div class="wen">营业执照副本</div>
            </div>
            <!-- <div style="display:flex; align-items: center;justify-content: flex-end;margin-right:100px">
                    <el-button type="primary">提交</el-button>
                </div> -->
        </div>
    </div>
</template>

<script>
import * as funMerchant from "@/api/merchant/index"
export default {
    name: "pageMerchantRecord",
    data() {
        return {
            uploadUrl: "",
            id: "",
            recordImg: {
                contractFImage: "", // 合同正面
                contractBImage: "", // 合同背面
                idCardFImage: "", // 身份证正面
                idCardBImage: "", // 身份证反面
                businessLicenseImage: "" // 营业执照副本
            }
        }
    },
    activated() {
        if (this.id != this.$route.query.id) {
            this.id = this.$route.query.id
            this.getImg()
        }
    },
    created() {
        this.uploadUrl = this.uploadBaseUrl + "/api/services/app/Shared/UploadFile"
        this.id = this.$route.query.id
        this.getImg()
    },
    methods: {
        handleAvatarSuccess(res, file, fileList, key) {
            // this.recordImg.bargain = URL.createObjectURL(file.raw)
            this.recordImg[key] = res.result.fileUrl
            this._save()
        },
        beforeUpload(file) {
            const isLt2M = file.size / 1024 / 1024 < 10
            if (!isLt2M) {
                this.errorMsg('上传图片大小不能超过 10MB!')
                return false
            }
            window.$common.fullLoading()
            return true
        },
        async getImg() {
            window.$common.fullLoading()
            funMerchant.GetArchiveInfo({ id: this.id }).then(res => {
                window.$common.closeFullLoading()
                this.recordImg.contractFImage = res && res.contractFImage //合同正面
                this.recordImg.contractBImage = res && res.contractBImage //合同背面
                this.recordImg.idCardFImage = res && res.idCardFImage //身份证正面
                this.recordImg.idCardBImage = res && res.idCardBImage //身份证反面
                this.recordImg.businessLicenseImage = res && res.businessLicenseImage //营业执照副本
            })
        },
        _save() {
            funMerchant.UpdateArchive({ id: this.id, archive: this.recordImg }).then(() => {
                window.$common.closeFullLoading()
            })
        }
    }
}
</script>